.parallax-banner {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6)
    );
    z-index: 1;
  }
}

.banner-content {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 2rem;
}

// Animations
@keyframes float {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-15px) rotate(5deg);
  }
  75% {
    transform: translateY(15px) rotate(-5deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

// Responsive adjustments
@media (max-width: 600px) {
  .parallax-banner {
    height: 50vh !important;
  }
}

// Custom styles for maternity section
.maternity-section {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("https://images.unsplash.com/photo-1584559582128-b8be739912e1?q=80&w=200")
      no-repeat center center;
    background-size: cover;
    opacity: 0.03;
    z-index: -1;
    pointer-events: none;
  }
}

// Enhanced card hover effects
.card-hover {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  }
}

// Gradient text effects
.gradient-text {
  background: linear-gradient(45deg, #ff69b4, #ff1493);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

// Base styles
.section-title {
  position: relative;
  display: inline-block;
  padding-bottom: 15px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(to right, #ff69b4, #ff1493);
    border-radius: 3px;
  }
}

// Responsive design
@media (max-width: 960px) {
  .section-title {
    &::after {
      width: 40px;
    }
  }
}

// Custom background patterns
.pattern-bg {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(#ff69b4 1px, transparent 1px);
    background-size: 20px 20px;
    opacity: 0.1;
    z-index: -1;
  }
}

// Enhanced transitions
.smooth-transition {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

// Icon container styles
.icon-container {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.2) 0%,
      transparent 70%
    );
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover::after {
    opacity: 1;
  }
}

// Gradient backgrounds
.gradient-bg {
  background: linear-gradient(135deg, #fff5f8 0%, #fff 100%);
}
