.teens-home {
  overflow-x: hidden;

  .parallax-banner {
    height: 50vh;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .banner-content {
      position: relative;
      z-index: 2;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      padding: 2rem;

      .main-title {
        font-size: 4rem;
        font-weight: bold;
        margin-bottom: 1rem;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

        @media (max-width: 768px) {
          font-size: 2.5rem;
        }
      }

      .subtitle {
        font-size: 2rem;
        font-weight: 300;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

        @media (max-width: 768px) {
          font-size: 1.5rem;
        }
      }
    }
  }

  .features-section {
    .feature-card {
      height: 100%;
      text-align: center;
      transition: transform 0.3s ease;
      background: linear-gradient(145deg, #ffffff, #f5f5f5);
      border-radius: 15px;
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);

      &:hover {
        transform: translateY(-10px);
      }

      .feature-icon {
        font-size: 3rem;
        margin-bottom: 1rem;
        color: #2196f3;
      }
    }
  }

  .nutrition-tips-section {
    .section-title {
      text-align: center;
      margin-bottom: 2rem;
      color: #333;
      font-weight: bold;
    }

    .tip-card {
      height: 100%;
      background: white;
      border-radius: 15px;
      transition: all 0.3s ease;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);

      &:hover {
        transform: scale(1.02);
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
      }

      .tip-icon {
        font-size: 2.5rem;
        color: #4caf50;
        margin-bottom: 1rem;
      }
    }
  }

  .mid-page-banner {
    height: 25vh;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1;
    }

    .banner-content {
      position: relative;
      z-index: 2;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      padding: 1rem;

      .quote {
        font-size: 2.8rem;
        font-weight: 400;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        max-width: 800px;
        margin: 0 auto;
        letter-spacing: 0.5px;
        line-height: 1.2;

        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }
  }

  :global {
    .parallax-provider {
      overflow-x: hidden;
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .parallax-outer {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }
}
