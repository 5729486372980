.landing-page {
  .parallax-banner {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1;
    }
  }

  .banner-content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    text-align: center;
    padding: 2rem;
  }

  .banner-title {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

    @media (max-width: 600px) {
      font-size: 2.5rem;
    }
  }

  .banner-subtitle {
    font-size: 1.5rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

    @media (max-width: 600px) {
      font-size: 1.2rem;
    }
  }

  .feature-box {
    padding: 2rem;
    border-radius: 8px;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    height: 100%;
    text-align: center;

    &:hover {
      transform: translateY(-5px);
    }
  }

  .feature-icon {
    font-size: 2.5rem;
    color: #2196f3;
    margin-bottom: 1rem;
  }

  .cta-button {
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
    border-radius: 30px;
    text-transform: none;

    &:hover {
      transform: scale(1.05);
    }
  }

  .mt-8 {
    margin-top: 4rem;
  }

  .mb-8 {
    margin-bottom: 4rem;
  }

  .mb-3 {
    margin-bottom: 1rem;
  }
}
