@import "content-colors";

$semantic-colors: (
  "primary": $chili-blue,
  "secondary": $peach,
  "success": $aquamarine,
  "info": $sky,
  "warning": $egg-yolk,
  "danger": $sunset,
);

$background-colors: ();

$text-colors: ();

$border-colors: ();

$custom-colors: ();

// Merge the maps
$theme-colors: map-merge($semantic-colors, $custom-colors);

$navbar-light-active-color: $white;
$navbar-light-color: $white;
$navbar-light-disabled-color: rgba($white, 0.25);
$navbar-light-hover-color: $white;

body {
  font: 16px/1.375 "Alef";
  direction: rtl;
}

.nav-link.active {
  font-weight: normal !important;
}
