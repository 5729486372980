.vegan-landing {
  overflow-x: hidden;

  .parallax-banner {
    height: 50vh;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1;
    }
  }

  .banner-content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 2rem;

    .banner-title {
      font-size: 4rem;
      font-weight: 700;
      margin-bottom: 1rem;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    .banner-subtitle {
      font-size: 2rem;
      font-weight: 400;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }

  .section-title {
    text-align: center;
    margin-bottom: 3rem;
    color: #2c3e50;
    font-weight: 600;
  }

  .benefit-card {
    text-align: center;
    padding: 2rem;
    height: 100%;
    background: white;
    border-radius: 15px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-10px);
    }

    .benefit-icon {
      font-size: 3rem;
      margin-bottom: 1.5rem;
      color: #4caf50;
    }
  }

  .nutrition-section {
    background: #f8f9fa;
    padding: 4rem 0;
    border-radius: 20px;

    .nutrition-content {
      padding: 2rem;

      h4 {
        color: #2c3e50;
        margin-top: 1.5rem;
      }
    }

    .nutrition-image {
      width: 100%;
      height: 400px;
      object-fit: cover;
      border-radius: 15px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    }
  }

  .lifestyle-section {
    .lifestyle-card {
      text-align: center;
      padding: 2rem;
      background: white;
      border-radius: 15px;
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
      height: 100%;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }

      .lifestyle-icon {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
        color: #4caf50;
      }
    }
  }
}

// RTL Support
[dir="rtl"] {
  .vegan-landing {
    text-align: right;
  }
}
