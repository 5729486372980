.parallax-banner {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6)
    );
    z-index: 1;
  }
}

.banner-content {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 2rem;
}

// Animations
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

// Responsive adjustments
@media (max-width: 600px) {
  .parallax-banner {
    height: 50vh !important;
  }
}
